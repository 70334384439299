import { createAction, props } from '@ngrx/store';

import { TreatmentType } from '../../models';

export const loadRequest = createAction('[Treatment Types] Load Request');

export const loadSuccess = createAction(
  '[Treatment Types] Load Success',
  props<{ items: TreatmentType[] }>(),
);

export const loadFailure = createAction(
  '[Treatment Types] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Treatment Types] Reset State');
